// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
// --------ElementUI 引入---------
// import ElementUI from 'element-ui';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ELEMENT);
// ---------------------------------
// 引入自定义的全局公共css----------
import './assets/css/common.css'
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
// 引入echarts--------------
// import echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 长连接 
import * as socketApi from '../public/static/ws'
Vue.prototype.socketApi = socketApi
// Vue.prototype.$options = $options
// ------------------------设置全局url---------------------------

// Vue.prototype.$baseurl = 'http://assrmyy-api.cqsqy.com';
// Vue.prototype.$baseurl = 'http://assrmyy-api.cqsqy.com';
Vue.prototype.$baseurl = "";
// 正式站
// Vue.prototype.$baseurl = "https://assrmyy-api.cqsqy.com"

//获取全局配置参数
import {
    getListConfig
} from "@/api/comm.js";
var config = JSON.parse(localStorage.getItem("config"));
if (!config) {
    getListConfig().then(res => {
        if (res.code == 400200) {
            document.title = res.data.systematic_name;
            localStorage.setItem("config", JSON.stringify(res.data));
        }
    });
} else {
    document.title = config.systematic_name;
}












// ------------------------------------
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')